import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {dom} from '@fortawesome/fontawesome-svg-core'
import setup from "@/services/setup.interceptors";
import VueMeta from 'vue-meta';
import Meta from 'vue-meta';
import '@/scss/base.scss';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueGtag from "vue-gtag";
import VueLazyload from 'vue-lazyload'
import VCalendar from 'v-calendar';
import VueI18n from "vue-i18n";



Vue.use(VueI18n)
function loadLocaleMessages () {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
}
export const i18n = new VueI18n({
    locale: 'fr',
    fallbackLocale: 'fr',
    messages: loadLocaleMessages()
})
Vue.use(VueLazyload)
Vue.use(VueMeta);
Vue.use(Meta);
Vue.use(VueToast, {
    position: 'top',
    duration: 6000,
})
dom.watch()
setup(store)

Vue.config.productionTip = false
// library.add(faHatWizard, faMagnifyingGlass, faFacebookF, faTriangleExclamation, faTruck, faInstagram, faLinkedinIn,
//     faTwitter, faYoutube, faEye, faEyeSlash, faUser, faEnvelope, faPencil, faLock,faFileInvoice)
// Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VCalendar, {
    componentPrefix: 'vc',
})

Vue.use(VueGtag, {
    config: { id: "G-J2J9YDBPYC" }
}, router);


new Vue({
    router,
    store,
    i18n,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')
