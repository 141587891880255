import {getAPI} from "@/services/axios-api";

const state = () => ({
    pricelistItems: [],
});

const getters = {
    getPricelistItems: function (state) {
        return state.pricelistItems;
    },
};

const actions = {
    loadPricelistTransport: async function (context) {
        return await getAPI
            .get('/product/pricelist/')
            .then(response => {
                context.commit('setPricelistTransport', {
                    pricelist: response.data.pricelist,
                })
                context.dispatch('cart/setRoute', {'id': response.data.route_id}, {root: true});
                context.dispatch('verifyCart');
                context.dispatch('products/setLowerPrice', false, {root: true});
                context.dispatch('products/setLowerPrice', true, {root: true});
            })
            .catch(err =>{
            })
    },
    verifyCart: function ({rootState, dispatch}) {
        let error = {
            message: "",
            detail: [],
        }
        let isWrong = false;
        if (rootState.cart.cart.items.length > 0) {
            rootState.cart.cart.items.forEach(element => {
                let priceItem = rootState.pricelist.pricelistItems.find(obj => {
                    return obj.product.id === element.product.id;
                })
                if(priceItem) {
                    element.price_df = priceItem.fixed_price;
                    element.price = priceItem.price_it;
                } else {
                    error.detail.push(element.product);
                    isWrong = true;
                }
            })
        }
        if (isWrong) {
            if (error.detail.length > 1) {
                error.message = "Ce produit n'est pas disonible pour cette adresse de livraison"
            } else {
                error.message = "Ces produits ne sont pas disonible pour cette adresse de livraison"
            }
            dispatch('error/setError', error, {root: true})
        }
        dispatch('cart/getShippingCost', null, {root: true})
    }
};

const mutations = {
    setPricelistTransport: function (state, data) {
        state.pricelistItems = data.pricelist
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};