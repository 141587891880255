<template>
  <div class="legal">
    <h2 class="title">MENTIONS LÉGALES</h2>
    <p class="infos">Merci de lire attentivement les modalités d'utilisation du présent site avant de le parcourir. En
      vous connectant sur ce site, vous acceptez sans réserve les présentes modalités. Conformément à l'article n°6 de
      la Loi n°2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, les responsables du présent site
      internet sont :</p>
    <section>
      <h3>EDITEUR DU SITE :</h3>
      <div>
        <p>La Famille Boutrais SARL</p>
        <p>Numéro de SIRET : 528 404 247 00017</p>
        <p>Responsable éditorial : Hugo Boutrais</p>
        <p>Adresse : Les Mousquemers, Zone d’activités de Bel event, 35350 Saint-Coulomb</p>
        <p>Téléphone : <a aria-label="La famille Boutrais téléphone" href="tel:+0299894816">02 99 89 48 16</a></p>
        <p>Email : contact@lafamilleboutrais.com</p>
        <p>Site Web : www.lafamilleboutrais.com</p>
        <p>InfoTri :
          "Identifiant unique REP emballages ménagers: FR310729_01HXTW" / "Identifiant unique REP emballages papiers:
          FR310729_03AUGC"</p>
      </div>
    </section>
    <section>
      <h3>HÉBERGEUR & DEVELOPPEMENT :</h3>
      <div>
        <p>SAS KERBI</p>
        <p>Numéro de SIRET : 919 594 549 00017</p>
        <p>Adresse : Les Mousquemers, Zone d’activités de Bel event, 35350 Saint-Coulomb</p>
        <p>Téléphone : <a aria-label="KERBI telephone" href="tel:+0299894816">02 99 89 48 16</a></p>
        <p>Email : contact@lafamilleboutrais.com</p>
      </div>
    </section>
    <section>
      <h3>CONDITIONS D’UTILISATION :</h3>
      <div>
        <p>Ce site est proposé en différents langages web (HTML, HTML5, Javascript, CSS, etc.) pour un meilleur confort
          d'utilisation et un graphisme plus agréable. Nous vous recommandons de recourir à des navigateurs modernes
          comme Internet Explorer, Safari, Firefox, Google Chrome, etc. La société met en œuvre tous les moyens dont
          elle dispose pour assurer une information fiable et une mise à jour fiable de ses sites internet. Toutefois,
          des erreurs ou omissions peuvent survenir. L'internaute devra donc s'assurer de l'exactitude des informations
          auprès de contact@lafamilleboutrais.com et signaler toutes modifications du site qu'il jugerait utile. La
          société La Famille Boutrais n'est en aucun cas responsable de l'utilisation faite de ces informations, et de
          tout préjudice direct ou indirect pouvant en découler.</p>
      </div>
    </section>
    <section>
      <h3>COOKIES :</h3>
      <div>
        <p>Le site peut être amené à vous demander l'acceptation des cookies pour des besoins de statistiques et
          d'affichage. Un cookie est une information déposée sur votre disque dur par le serveur du site que vous
          visitez. Il contient plusieurs données qui sont stockées sur votre ordinateur dans un simple fichier texte
          auquel un serveur accède pour lire et enregistrer des informations. Certaines parties de ce site ne peuvent
          être fonctionnelles sans l'acceptation de cookies.</p>
      </div>
    </section>
    <section>
      <h3>LIENS HYPERTEXTES :</h3>
      <div>
        <p>Les sites internet de La Famille Boutrais peuvent offrir des liens vers d'autres sites internet ou d'autres
          ressources disponibles sur Internet. La société La Famille Boutrais ne dispose d'aucun moyen pour contrôler
          les sites en connexion avec ses sites internet. Elle ne répond pas de la disponibilité de tels sites et
          sources externes, ni ne la garantit. Elle ne peut être tenue pour responsable de tout dommage, de quelque
          nature que ce soit, résultant du contenu de ces sites.
        </p>
      </div>
    </section>
    <section>
      <h3>LIMITATION CONTRACTUELLES SUR LES DONNÉES :</h3>
      <div>
        <p>Les informations contenues sur ce site sont aussi précises que possible et le site est remis à jour à
          différentes périodes de l'année, mais il peut toutefois contenir des inexactitudes ou des omissions. Si vous
          constatez une lacune, une erreur ou ce qui semble être un dysfonctionnement, merci de bien vouloir le signaler
          par e-mail à l'adresse contact@lafamilleboutrais.com en décrivant le problème de la manière la plus précise
          possible (page posant un problème, type d'ordinateur et de navigateur utilisé, etc.). Tout contenu téléchargé
          se fait aux risques et périls de l'utilisateur et sous sa seule responsabilité. En conséquence, La Famille
          Boutrais ne saurait être tenue responsable d'un quelconque dommage subi par l'ordinateur de l'utilisateur ou
          d'une quelconque perte de données consécutives au téléchargement. De plus, l'utilisateur du site s'engage à
          accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière
          génération mis à jour. Les liens hypertextes mis en place dans le cadre du présent site Internet en direction
          d'autres ressources présentes sur le réseau Internet ne sauraient engager la responsabilité de La Famille
          Boutrais.</p>
      </div>
    </section>
    <section>
      <h3>PROPRIÉTÉ INTELLECTUELLE DU SITE :</h3>
      <div>
        <p>Tout le contenu du présent site , incluant, de façon non limitative, les graphismes, images, textes, vidéos,
          animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de la société
          à l’exception des marques, logos ou contenus appartenant à d’autres sociétés partenaires ou auteurs. Toute
          reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces
          différents éléments est strictement interdite sans l’accord exprès par écrit de la SARL La Famille Boutrais .
          Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée
          par les articles L.335-2 et suivants du Code de la propriété intellectuelle. Le non-respect de cette
          interdiction constitue une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur.
          En outre, les propriétaires des Contenus copiés pourraient intenter une action en justice à votre
          encontre.</p>
      </div>
    </section>
    <section>
      <h3>DÉCLARATION À LA CNIL :</h3>
      <div>
        <p>Conformément à la loi 78-17 du 6 janvier 1978 (modifiée par la loi 2004-801 du 6 août 2004 relative à la
          protection des personnes physiques à l'égard des traitements de données à caractère personnel)</p>
      </div>
    </section>
    <section>
      <h3>LITIGES :</h3>
      <div>
        <p>Les présentes conditions du site sont régies par les lois françaises et toute contestation ou litiges qui
          pourraient naître de l’interprétation ou de l’exécution de celles-ci seront de la compétence exclusive des
          tribunaux dont dépend le siège social de la société. La langue de référence, pour les règlements de
          contentieux éventuels, est le français.</p>
      </div>
    </section>
    <section>
      <h3>DONNÉES PERSONNELLES :</h3>
      <div>
        <p>De manière générale, vous n’êtes pas tenu de nous communiquer vos données personnelles lorsque vous visitez
          notre site Internet . Cependant, ce principe comporte certaines exceptions. En effet, pour certains services
          proposés par notre site, vous pouvez être amenés à nous communiquer certaines données telles que : votre nom,
          votre fonction, le nom de votre société, votre adresse électronique, et votre numéro de téléphone. Tel est le
          cas lorsque vous remplissez le formulaire qui vous est proposé en ligne, dans la rubrique « contact ». Dans
          tous les cas, vous pouvez refuser de fournir vos données personnelles. Dans ce cas, vous ne pourrez pas
          utiliser les services du site, notamment celui de solliciter des renseignements sur notre société, ou de
          recevoir les lettres d’information. Enfin, nous pouvons collecter de manière automatique certaines
          informations vous concernant lors d’une simple navigation sur notre site Internet, notamment : des
          informations concernant l’utilisation de notre site, comme les zones que vous visitez et les services auxquels
          vous accédez, votre adresse IP, le type de votre navigateur, vos temps d’accès. De telles informations sont
          utilisées exclusivement à des fins de statistiques internes, de manière à améliorer la qualité des services
          qui vous sont proposés. Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998
          transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "LegalNotice",
  metaInfo: {
    title: 'Mentions légales',
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" type="text/scss" scoped>
.legal {

}

.title {
  text-align: center;
  color: $Brown;
}

.infos {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 1%;
  font-size: small;
}

section {
  h3 {
    color: $Brown;
    text-align: center;
    margin-bottom: 1%;
  }

  p {
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 1%;
    font-size: small;
    font-style: italic;

    a {
      color: #ae7935;
      text-decoration: none;
    }
  }
}

</style>