<template>
  <div id="app" >
    <NavMenu/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>

const NavMenu = () => import ("@/components/NavMenu.vue")
const Footer = () => import ("@/components/Footer.vue")
const LocationModal = () => import ("@/components/LocationModal.vue")
import {getAPI} from "@/services/axios-api";
import {mapGetters, mapState} from "vuex";


export default {
  name: 'app',
  metaInfo: function () {
    return {
      title:'Achetez des huîtres en ligne en direct du producteur',
      titleTemplate:'%s | La Famille Boutrais',
      meta:[
        { name: 'viewport', content: 'width=device-width,initial-scale=1, shrink-to-fit=no,maximum-scale=5.0,user-scalable=no, minimal-ui'},
      ],
    }
  },
  components: {
    NavMenu,
    Footer,
    LocationModal
  },
  data () {
    return {

    }
  },
  beforeCreate() {
    let c = localStorage.getItem('c')
    if (c) {
      this.$store.commit('category/setCategory', c)
    }
    this.$store.dispatch('auth/initializeStore');
    const access = this.$store.getters["auth/getAccessToken"]
    if(access) {
      getAPI.defaults.headers.common['Authorization'] = access;
    } else {
      getAPI.defaults.headers.common['Authorization'] = ""
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleDebouncedScroll);
  },
  mounted() {},
  computed: {
    ...mapGetters(['stateLModal', 'stateLoading']),
    ...mapState('auth',['isAuthenticated','getUserId']),
  },
  watch: {
    $route: function() {
      if (this.$route.meta.locateModal) this.$store.dispatch('cart/changeLocateStatus', true)
      else this.$store.dispatch('cart/changeLocateStatus', false)
    },

  },
  methods: {

  },
}
</script>
<style lang="scss">
#app {
  font-family: 'Open Sans', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
