import {getAPI} from "@/services/axios-api";

const state = () => ({
    listRespartner: [],
});

const getters ={
    getListRespartner : function (state){
        return state.listRespartner;
    },
};

const actions = {
    updateUser: async function (context, formData){
        await getAPI
            .patch('/account/profile/' + formData.userId +'/', formData)
            .catch(error => console.log(error.data))
    },
    loadRespartner: async function (context){
        if(context.state.listRespartner.length<=0){
            await getAPI
                .get('/respartner/')
                .then(response => {
                    context.commit('setlistRespartner', response.data) })
                .catch(errors => {console.log(errors)})
        }
    },
};

const mutations ={
    setlistRespartner : function (state, data){
        state.listRespartner = data;
    },
};

export default {
    namespaced: true, state, getters, actions, mutations,
};