import {getAPI} from "@/services/axios-api";

const state = () => ({
    metaData: {},
    metaDataBlog: {},
});

const getters = {
    getMeta: function (state){
        return state.metaData
    },
    getMetaBlog:function (state){
        return state.metaDataBlog
    },
};
const actions = {
    getMetaData: async function (context, id){
        await getAPI.get('/product/category/'+id+'/' ).then(res => {
            context.commit('setMetaData', res.data)
        })
    },
    getMetaDataBlog: async function (context, url){
        await getAPI.get('/ecommerce/' + url + '/').then(res => {
            context.commit('setMetaDataBlog', res.data)
        })
    },
};
const mutations = {
    setMetaData:function(state, data){
        state.metaData = data
    },
    setMetaDataBlog:function(state, data){
        state.metaDataBlog = data
    }
};


export default {
    namespaced: true, state, getters, actions, mutations,
}