import {getAPI} from "@/services/axios-api";
import {CartService} from "@/services/cart.service";

const state = () => ({
    errorMessage: null,
    errorDetails: null,
});

const getters = {
    getErrorMessage : function (state) {return state.errorMessage;},
    getErrorDetail : function (state) {return state.errorDetails;},
};

const actions = {
    setError: function (context, error) {
        context.commit('setErrorMessage', error.message);
        context.commit('setErrorDetails', error.details);
    },
};

const mutations = {
    setErrorMessage: function (state, message) {
        state.errorMessage = message
    },
    setErrorDetails: function (state, details) {
        state.errorDetails = details
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};