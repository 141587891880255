import {getAPI} from "@/services/axios-api";

const state = () => ({
    orderData:null,
});
const getters = {
    getOrderData: function (state){return state.orderData},
};

const actions = {
    setOrderData: async function (context, data) {
        Storage.prototype.setObjet = function(cle, objet) {
            this.setItem(cle, JSON.stringify(objet));
        }
        context.commit('setOrderData', data)
        localStorage.setObjet('ord', data);
    },
    clearOrderData: function ({commit}) {
        commit('clearOrderData')
    }
};

const mutations = {
    setOrderData:function(state, order){
        state.orderData = order;
    },
    clearOrderData:function (state){
        state.orderData=null;
    }

};

export default {
    namespaced: true, state, getters, actions, mutations,
};