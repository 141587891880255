import Vue from 'vue'
import Vuex from 'vuex'
import auth from "@/store/module/auth";
import cart from "@/store/module/cart";
import pricelist from "@/store/module/pricelist";
import partner from "@/store/module/partner";
import error from "@/store/module/error";
import category from "@/store/module/category";
import order from "@/store/module/order";
import products from "@/store/module/products";
import metaData from "@/store/module/metaData";
import google from "@/store/module/google";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        connexionModal: false,
        locationModal: false,
        showPartnerModal: false,
        selectRespartnerDelivery: false,
        selectRespartnerInvoice: false,
        isLoading: false,
        sizes: '(max-width: 320px) 280px, (max-width: 480px) 440px, 800px',
    },
    getters: {
        stateCModal: function (state) {
            return state.connexionModal;
        },
        stateLModal: function (state) {
            return state.locationModal;
        },
        stateShowPartnerModal: function (state) {
            return state.showPartnerModal
        },
        stateSelectRespartnerDelivery: function (state) {
            return state.selectRespartnerDelivery
        },
        stateSelectRespartnerInvoice: function (state) {
            return state.selectRespartnerInvoice
        },
        stateLoading: function (state) {
            return state.isLoading
        },
    },
    mutations: {
        setIsLoading(state, status) {
            state.isLoading = status
        },
        changeStateCModal: function (state, data) {
            state.connexionModal = data
        },
        changeStateLModal: function (state, data) {
            state.locationModal = data
        },
        changeStateShowPartnerModal: function (state, data) {
            state.showPartnerModal = data;
        },
        changeSelectRespartnerDelivery: function (state, data) {
            state.selectRespartnerDelivery = data;
        },
        changeSelectRespartnerInvoice: function (state, data) {
            state.selectRespartnerInvoice = data;
        },
    },
    actions: {
        initiateLocationModal: async function (context) {
            if (context.state.auth.location !== null) {
                context.commit('changeStateLModal', false);
            } else context.commit('changeStateLModal', true);
        },
        changeStateCModal: function (context, data) {
            context.commit('changeStateCModal', data)
        },
        changeStateLModal: function (context, data) {
            context.commit('changeStateLModal', data)
        },
        changeStateShowPartnerModal: function (context, data) {
            context.commit('changeStateShowPartnerModal', data)
        },
        changeSelectRespartnerDelivery: function (context, data) {
            context.commit('changeSelectRespartnerDelivery', data)
        },
        changeSelectRespartnerInvoice: function (context, data) {
            context.commit('changeSelectRespartnerInvoice', data)
        },
        changeStateLoading: function (context, data) {
            context.commit('setIsLoading', data)
        }
    },
    modules: {
        auth: auth,
        cart: cart,
        pricelist: pricelist,
        partner: partner,
        error: error,
        category: category,
        order: order,
        products: products,
        metaData: metaData,
        google: google,
    }
})
