import {getAPI} from "@/services/axios-api";
import {Utils} from "@/services/utils";
import Vue from "vue";

const state = () => ({
    products: [],
    related: [],
});

const getters = {
    getProductsList: function (state) {
        return state.products;
    },
    getProductsRelatedList: function (state) {
        return state.related;
    },
};

const actions = {
    getProducts: async function ({dispatch, rootState, commit}, data) {
        dispatch('changeStateLoading', true, {root: true});
        let prices = data.prices
        try {
            if (prices.length === 0) {
                await dispatch('pricelist/loadPricelistTransport', null, {root: true})
            }
        } catch (error) {
            await dispatch('pricelist/loadPricelistTransport', null, {root: true})
        }
        await getAPI
            .get('/product/template/', {
                params: {
                    "category": data.category,
                }
            })
            .then(response => {
                commit('setProducts', response.data.data)
                dispatch('setLowerPrice', false)
            })
            .catch(errors => {
                console.log(errors);
            })
        dispatch('changeStateLoading', false, {root: true})
    },
    setLowerPrice: function (context, related) {
        const prices = context.rootState.pricelist.pricelistItems
        let products;
        if (related) {
            products = context.state.related
        } else {
            products = context.state.products
        }
        if(products) {
            products.forEach(e => {
                Vue.set(e, 'lower_price', Utils.getLowerPrice(prices, e))
            })
            if (related) {
                context.commit('setProductsRelated', products)
            } else {
                context.commit('setProducts', products)
            }
        }
    },
    getRelatedProduct: async function ({dispatch, rootState, commit}, data) {
        let prices = data.prices
        try {
            if (prices.length === 0) {
                await dispatch('pricelist/loadPricelistTransport', null, {root: true})
            }
        } catch (error) {
            await dispatch('pricelist/loadPricelistTransport', null, {root: true})
        }
        let promise;
        if (data.productId) {
            promise = await getAPI
                .get('/product/related/' + data.productId)
        } else {
            promise = await getAPI
                .get('/product/related/', {
                    params: {
                        products: data.productIds
                    }
                })
        }
        commit('setProductsRelated', promise.data.data)
        dispatch('setLowerPrice', true)
    },
};

const mutations = {
    setProducts: function (state, data) {
        state.products = data
    },
    setProductsRelated: function (state, data) {
        state.related = data
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};