import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from "@/store";
import LegalNotice from "@/views/LegalNotice";
import GeneralConditionsSale from "@/views/GeneralConditionsSale";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: Home
    },
    {
        path: '/mentions-legales',
        name: 'LegalNotice',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: LegalNotice
    },
    {
        path: '/conditions-generale-de-vente',
        name: 'GeneralConditionsSale',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: GeneralConditionsSale
    },
    {
        path: "/logout",
        name: "logout",
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: {
            beforeRouteEnter(to, from, next) {
                const destination = {
                    path: "/",
                };
                store.dispatch("auth/logout");
                next(destination);
                window.location.reload();
            }
        }
    },
    {
        path: '/compte/inscription',
        name: 'Registration',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "compte/inscription" */ '../views/partner/Registration.vue')
        }
    },
    {
        path: '/compte/profil/',
        name: 'Account',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "/compte-client/profil/" */ '../views/partner/Account')
        }
    },

    {
        path: '/compte/adresses/',
        name: 'Address',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "compte-client/adresses" */ '../views/partner/Address')
        }
    },
    {
        path: '/compte/aide&contact/',
        name: 'Help',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "compte/aide&contact" */ '../views/partner/Help')
        }
    },
    {
        path: '/contact/',
        name: 'ContactUs',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "contact" */ '../views/ContactUs')
        }
    },
    {
        path: '/compte/adresse/:id',
        name: 'EditAddress',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        props: true,
        component: function () {
            return import(/* webpackChunkName: "partner" */ '../views/partner/EditAddress.vue')
        }
    },
    {
        path: '/compte/ajouter/adresse',
        name: 'AddAddress',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        props: true,
        component: function () {
            return import(/* webpackChunkName: "/partner/ajout/adresse" */ '../views/partner/AddAddress.vue')
        }
    },
    {
        path: '/compte/rattachement/:uidb64/:token',
        name: 'LinkAccount',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        props: true,
        component: function () {
            return import(/* webpackChunkName: "/partner/rattachement" */ '../views/partner/LinkAccount.vue')
        }
    },
    {
        path: '/compte/commandes/',
        name: 'Invoice',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "compte/commandes" */ '../views/partner/Invoice')
        }
    },
    {
        path: '/panier',
        name: 'Cart',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        props: true,
        component: function () {
            return import(/* webpackChunkName: "sale/cart" */ '../views/sale/Cart.vue')
        }
    },
    {
        path: '/achat/inscription/',
        name: 'CartRegistration',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "achat/inscription" */ '../views/sale/CartRegistration.vue')
        }
    },
    {
        path: '/achat/commande',
        name: 'Order',
        meta: {
            locateModal: false,
            hideLocation: true,
        },
        component: function () {
            return import(/* webpackChunkName: "achat/commande" */ '../views/sale/Order.vue')
        }
    },
    {
        path: '/achat/paiement',
        name: 'Payment',
        meta: {
            locateModal: false,
            hideLocation: true,
        },
        component: function () {
            return import(/* webpackChunkName: "achat/paiement" */ '../views/sale/Payment.vue')
        }
    },
    {
        path: '/achat/paiement/erreur',
        name: 'PaymentError',
        meta: {
            locateModal: false,
            hideLocation: true,
        },
        component: function () {
            return import(/* webpackChunkName: "achat/paiement/error" */ '../views/sale/PaymentError')
        }
    },
    {
        path: '/achat/confirmation',
        name: 'Purchase',
        props: true,
        meta: {
            locateModal: false,
            hideLocation: true,
        },
        component: function () {
            return import(/* webpackChunkName: "achat/confirmation" */ '../views/sale/Purchase.vue')
        }
    },
    {
        path: '/achat/bon-cadeau',
        name: 'ValidateGift',
        props: true,
        meta: {
            locateModal: false,
            hideLocation: true,
        },
        component: function () {
            return import(/* webpackChunkName: "achat/carte-cadeau" */ '../views/sale/ValidateGift')
        }
    },
    {
        path: '/a-propos/qui-sommes-nous',
        name: 'WhoAreWe',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        props: true,
        component: function () {
            return import(/* webpackChunkName: "shop" */ '../views/about/WhoAreWe.vue')
        }
    },
    {
        path: '/a-propos/comment-ca-marche',
        name: 'HowItWork',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        props: true,
        component: function () {
            return import(/* webpackChunkName: "shop" */ '../views/about/HowItWork.vue')
        }
    },
    {
        path: '/a-propos/nos-points-de-vente',
        name: 'PointsOfSales',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        props: true,
        component: function () {
            return import(/* webpackChunkName: "shop" */ '../views/about/PointsOfSales.vue')
        }
    },
    // {
    //     path: '/a-propos/prestations',
    //     name: 'Services',
    //     meta: {
    //         locateModal: true,
    //         hideLocation: false,
    //     },
    //     props: true,
    //     component: function () {
    //         return import(/* webpackChunkName: "shop" */ '../views/about/Services.vue')
    //     }
    // },
    {
        path: '/a-propos/dundrum',
        name: 'Dundrum',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        props: true,
        component: function () {
            return import(/* webpackChunkName: "shop" */ '../views/about/Dundrum.vue')
        }
    },
    {
        path: '/a-propos/trawenagh',
        name: 'Trawenagh',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        props: true,
        component: function () {
            return import(/* webpackChunkName: "shop" */ '../views/about/Trawenagh.vue')
        }
    },
    {
        path: '/a-propos/baie-saint-michel',
        name: 'SaintMichel',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        props: true,
        component: function () {
            return import(/* webpackChunkName: "shop" */ '../views/about/SaintMichel.vue')
        }
    },
    {
        path: '/a-propos/bannow',
        name: 'Bannow',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        props: true,
        component: function () {
            return import(/* webpackChunkName: "shop" */ '../views/about/Bannow.vue')
        }
    },
    {
        path: '/produit/:id',
        name: 'Product',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        props: true,
        component: function () {
            return import(/* webpackChunkName: "produit" */ '../views/shop/Product.vue')
        }
    },
    {
        path: '/compte/motdepasse/modifier',
        name: 'PasswordReset',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "/compte/motdepasse/modifier" */ '../views/partner/PasswordReset.vue')
        }
    },
    {
        path: '/compte/motdepasse/envoyer',
        name: 'PasswordResetSend',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "/compte/motdepasse/envoyer" */ '../views/partner/PasswordResetSend.vue')
        }
    },
    {
        path: '/compte/motdepasse/form/:uidb64/:token',
        name: 'PasswordResetForm',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "/compte/motdepasse/form" */ '../views/partner/PasswordResetForm.vue')
        }
    },
    {
        path: '/compte/motdepasse/valide',
        name: 'PasswordResetDone',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "/compte/motdepasse/valide" */ '../views/partner/PasswordResetDone.vue')
        }
    },
    {
        path: '/compte/email/envoi',
        name: 'EmailVerifySend',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "//compte/email/envoi" */ '../views/partner/EmailVerifySend.vue')
        }
    },
    {
        path: '/compte/email/valide/:uidb64',
        name: 'EmailVerifyDone',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "/partner/email/done" */ '../views/partner/EmailVerifyDone.vue')
        }
    },
    {
        path: '/actualites/:url',
        name: 'Post',
        props: true,
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "blog/article" */ '../views/blog/Post')
        }
    },
    {
        path: '/blog/recette',
        name: 'Recipes',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "/partner/email/done" */ '../views/blog/Recipes')
        }
    },
    {
        path: '/a-propos/nos-engagements',
        name: 'CommitmentFB',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "/nos-engagements" */ '../views/about/CommitmentFB')
        }
    },
    {
        path: '/famille-boutrais',
        name: 'Pascal',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "/famille-boutrais" */ '../views/about/Pascal')
        }
    },
    {
        path: '/notre-histoire',
        name: 'SuccessStory',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "/notre-histoire" */ '../views/about/SuccessStory')
        }
    },
    {
        path: '/notre-savoir-faire',
        name: 'KnowHow',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "/notre-savoir-faire" */ '../views/about/KnowHow')
        }
    },
    {
        path: '/blog',
        name: 'Blog',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "/blog" */ '../views/blog/Blog')
        }
    },
    {
        path: '/bon-cadeau',
        name: 'Gift',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        component: function () {
            return import(/* webpackChunkName: "/gift" */ '../views/Gift')
        }
    },
    {
        path: '/huitres-creuses',
        redirect: '/'
    },
    {
        path: '/:category',
        name: 'Shop',
        meta: {
            locateModal: true,
            hideLocation: false,
        },
        props: true,
        component: function () {
            return import(/* webpackChunkName: "shop" */ '../views/shop/Shop.vue')
        }
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({ el: to.hash })
                }, 200)
            })
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y:0 }
        }
    }
})
export default router
