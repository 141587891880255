export class TokenService {
    static getLocalRefreshToken() {
        return sessionStorage.getItem('refresh')
    }

    static getLocalAccessToken() {
        return sessionStorage.getItem('access')
    }

    static updateLocalAccessToken(token) {
        return sessionStorage.setItem('access', JSON.stringify(token))
    }
}

export default new TokenService();
