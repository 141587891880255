export class CartService {

    constructor(pricelistItems) {
        this.pricelistItems = null;
        this.productIds = null;
        this.errors = [];
        this.itemsError = [];
        this.setPricelistSettings(pricelistItems);
    }

    setPricelistSettings(pricelistItems) {
        this.pricelistItems = pricelistItems;
        this.productIds = pricelistItems.map(i => parseInt(i.product.id));
    }

    verifyCart(items) {
        items.forEach((i, index) => {
            if (this.productIds.includes(i.product.id)) {
                let item = this.pricelistItems.find(e => i.product.id === e.product.id);
                i.price = item.price_it;
                i.price_df = item.fixed_price;
            } else this.errors.push(index);
        })
        for (let i = this.errors.length -1; i >= 0; i--) {
            this.itemsError.push(items[i])
            items.splice(this.errors[i], 1);
        }
        return items;
    }

    getItems() {
        let string = ''
        this.itemsError.forEach(e => {
             string += e.product.name + "-" + e.product.default_code + ', \n'
        })
        return string
    }
}

export default CartService;