import Vue from "vue";

const state = () => ({});

const getters = {};
const actions = {
    trackEvent: function(context, request) {
        Vue.prototype.$gtag.event(request.action, request.eventParams)
    },
};
const mutations = {};


export default {
    namespaced: true, state, getters, actions, mutations,
}