<template>
  <div>
    <h2 class="title">CONDITIONS GÉNÉRALES DE VENTE</h2>
    <section>
      <h3>ARTICLE 1- Champ d’application</h3>
      <div>
        <p>Les présentes Conditions Générales de Vente s’appliquent, sans restriction ni réserve, à l’ensemble des
          ventes conclues par La Famille Boutrais (« le vendeur ») auprès d’acheteurs non professionnels (« Les clients
          ou le client ») , désirant acquérir les produits proposés à la vente par le vendeur (« Les produits ») sur le
          site Internet lafamilleboutrais.com Elles précisent notamment les conditions de commande, de paiement, de
          livraison et de gestion des éventuels retours des produits commandés par les clients. Les produits proposés à
          la vente sur le site Internet : lafamilleboutrais.com sont les suivants : coquillages, crustacés, huîtres,
          vins, accompagnements.</p>
        <p>Les caractéristiques principales des produits, et notamment les spécifications, illustrations et indications
          de dimensions ou de capacité des produits, sont présentées sur le site Internet : lafamilleboutrais.com</p>
        <p>Le client est tenu d’en prendre connaissance avant toute passation de commande.
          Le choix et l’achat d’un produit est de la seule responsabilité du client.
          Les photographies et graphismes présentés sur le site Internet : lafamilleboutrais.com ne sont pas
          contractuels et ne sauraient engager la responsabilité du vendeur.
          Le client est tenu de se reporter au descriptif de chaque produit afin d’en connaître les propriétés et les
          particularités essentielles.
          Les offres de produits s’entendent dans la limite des stocks disponibles, tels que précisés lors de la
          passation de la commande.
        </p>
        <p></p>
        <p>Les coordonnées du vendeur sont les suivantes :</p>
        <p>La Famille Boutrais</p>
        <p>Les Mousquemers, Zone d’activité de Bel Event, </p>
        <p>35350 St Coulomb</p>
        <p>Les présentes Conditions Générales de Vente s’appliquent à l’exclusion de toutes autres conditions, et
          notamment celles applicables pour les ventes en magasin ou au moyen d’autres circuits de distribution et de
          commercialisation.</p>
        <p>Ces Conditions Générales de Vente sont accessibles à tout moment sur le site Internet : lafamilleboutrais.com
          et prévaudront, le cas échéant, sur toute autre version ou tout autre document contradictoire.</p>
        <p>Le client déclare avoir pris connaissance des présentes Conditions Générales de Ventes et les avoir acceptées
          en cochant la case prévue à cet effet avant la mise en œuvre de la procédure de commande en ligne ainsi que
          des conditions générales d’utilisation du site Internet : lafamilleboutrais.com</p>
        <p>Ces Conditions Générales de Vente pouvant faire l’objet de modifications ultérieures, la version applicable à
          l’achat du client est celle en vigueur sur le site Internet à la date de passation de la commande.</p>
        <p>Sauf preuve contraire, les données enregistrées dans le système informatique du vendeur constituent la preuve
          de l’ensemble des transactions conclues avec le client.</p>
        <p>Conformément à la loi Informatique et Libertés du 6 janvier 1978, le client dispose, à tout moment, d’un
          droit d’accès, de rectification, et d’opposition à l’ensemble de ses données personnelles en écrivant, par
          courrier et en justifiant de son identité, à Les Mousquemers, Zone d’activité de Bel Event, 35350 St
          Coulomb.</p>
        <p>Les produits présentés sur le site Internet : lafamilleboutrais.com sont proposés à la vente pour les
          territoires suivants : France Métropolitaine.</p>
        <p>En cas de commande vers un pays autre que la France métropolitaine, le client est l’importateur du ou des
          produits concernés.</p>
        <p>Pour tous les produits expédiés hors Union européenne et DOM-TOM, le prix sera calculé hors taxes
          automatiquement sur la facture.</p>
        <p>Des droits de douane ou autres taxes locales ou droits d’importation ou taxes d’état sont susceptibles d’être
          exigibles. Ils seront à la charge et relèvent de la seule responsabilité du client.</p>
      </div>
    </section>
    <section>
      <h3>ARTICLE 2- Commandes</h3>
      <div>
        <p>Il appartient au client de sélectionner sur le site Internet : lafamilleboutrais.com les produits qu’il
          désire commander, selon les modalités suivantes :</p>
        <p>Commande par le site Internet indiqué ci-dessus</p>
        <p>Les offres de produits sont valables tant qu’elles sont visibles sur le site, dans la limite des stocks
          disponibles.</p>
        <p>La vente ne sera considérée comme définitive qu’après l’envoi à l’acheteur de la confirmation de
          l’acceptation de la commande par le vendeur par courrier électronique.
          Il appartient au client de vérifier l’exactitude de la commande et de signaler immédiatement toute erreur.
        </p>
        <p>Toute commande passée sur le site Internet lafamilleboutrais.com constitue la formation d’un contrat conclu à
          distance entre le client et le vendeur.</p>
        <p>Le client pourra suivre l’évolution de sa commande sur le site Internet : lafamilleboutrais.com</p>
        <p>Le vendeur n’a pas vocation à vendre les produits sur le site Internet : lafamilleboutrais.com à des
          professionnels, mais uniquement à des consommateurs, pour leurs besoins personnels.</p>
        <p>Le vendeur se réserve donc le droit de refuser les commandes d’un même produit en quantités importantes.</p>
        <p>Les éventuelles modifications de la commande par le client ne pourront être prises en compte par le vendeur
          que dans la limite de ses possibilités et à condition d’être notifiées par courriel au vendeur 1 jour au moins
          avant la date prévue pour l’expédition de la commande.</p>
        <p>Dans l’hypothèse où ces modifications ne pourraient être acceptées par le vendeur, les sommes versées par le
          client lui seront restituées dans un délai maximum de 30 jours à compter de la notification de l’impossibilité
          d’accepter les modifications demandées par le client (à moins que celui-ci ne préfère bénéficier d’un
          avoir).</p>
        <p>En cas d’annulation de la commande par le client après son acceptation par le vendeur moins de 1 jours au
          moins avant la date prévue pour la fourniture des produits commandés, pour quelque raison que ce soit hormis
          la force majeure, le prix versé à la commande, tel que défini à l’article “ Conditions de paiement ” des
          présentes Conditions Générales de Vente sera de plein droit acquis au vendeur et ne pourra donner lieu à un
          quelconque remboursement.</p>
      </div>
    </section>
    <section>
      <h3>ARTICLE 3 – Tarifs</h3>
      <div>
        <p>Les produits sont fournis aux tarifs en vigueur figurant sur le site Internet : lafamilleboutrais.com, lors
          de l’enregistrement de la commande par le vendeur. Les prix sont exprimés en Euros, HT et TTC.</p>
        <p>Les tarifs tiennent compte d’éventuelles réductions qui seraient consenties par le vendeur sur le site
          Internet : lafamilleboutrais.com/p>
        <p>Ces tarifs sont fermes et non révisables pendant leur période de validité, telle qu’indiqué sur le site
          Internet : lafamilleboutrais.com, le vendeur se réservant le droit, hors cette période de validité, de
          modifier les prix à tout moment. Ils ne comprennent pas les frais de traitement, d’expédition, de transport et
          de livraison, qui sont facturés en supplément, dans les conditions indiquées sur le site Internet :
          lafamilleboutrais.com et calculés préalablement à la passation de la commande.</p>
        <p>Le paiement demandé au client correspond au montant total de l’achat, y compris ces frais.</p>
        <p>D’éventuelles commandes spécifiques du client peuvent être envisagées. Le cas échéant, elles feront l’objet
          d’un devis auparavant accepté par celui-ci. Les devis établis par le vendeur sont valables pour une durée de
          “1 mois”, à compter de leur date d’établissement.</p>
        <p>La commande sur devis n’est considérée comme acceptée qu’après paiement du montant de la commande.</p>
        <p>Une facture est établie par le vendeur et remise au client lors de la livraison des produits commandés.</p>
      </div>
    </section>
    <section>
      <h3>ARTICLE 4 – Conditions de paiement</h3>
      <div>
        <p>Le prix est payable comptant, en totalité au jour de la passation de la commande par le Client, par voie de
          paiement sécurisé, selon les modalités suivantes : Paiement par PayZen.</p>
        <p>La mise à l’encaissement du chèque est réalisée à réception.</p>
        <p>Les données de paiement sont échangées en mode crypté grâce au protocole 3DSecure</p>
        <p>En outre, le vendeur se réserve le droit, en cas de non-respect des conditions de paiement figurant
          ci-dessus, de suspendre ou d’annuler la livraison des commandes en cours effectuées par le client.</p>
        <p>Aucun frais supplémentaire, supérieur aux coûts supportés par le vendeur pour l’utilisation d’un moyen de
          paiement ne pourra être facturé au client.</p>
      </div>
    </section>
    <section>
      <h3>ARTICLE 5 – Livraisons</h3>
      <div>
        <p>Les produits commandés par le client seront livrés en France métropolitaine dans un délai de 2 jours à
          compter de l’expédition de la commande à l’adresse indiquée par le client lors de sa commande sur le site
          Internet : lafamilleboutrais.com</p>
        <p>La livraison est constituée par le transfert au client de la possession physique ou du contrôle du
          produit.</p>
        <p>La livraison est constituée par le transfert au client de la possession physique ou du contrôle du
          produit.</p>
        <p>Sauf cas particulier ou indisponibilité d’un ou plusieurs produits, les produits commandés seront livrés en
          une seule fois.</p>
        <p>Le vendeur s’engage à faire ses meilleurs efforts pour livrer les produits commandés par le client dans les
          délais ci-dessus précisés. Toutefois, ces délais sont communiqués à titre indicatif. Cependant, si les
          produits commandés n’ont pas été livrés dans un délai de 2 jours après la date indicative de livraison, pour
          toute autre cause que la force majeure ou le fait du client, la vente pourra être résolue à la demande écrite
          du client dans les conditions prévues aux articles L 138-2 et L 138-3 du Code de la consommation. Les sommes
          versées par le client lui seront alors restituées au plus tard dans les quatorze jours qui suivent la date de
          dénonciation du contrat, à l’exclusion de toute indemnisation ou retenue.</p>
        <p>Les livraisons sont assurées par un transporteur indépendant, à l’adresse mentionnée par le client lors de la
          commande et à laquelle le transporteur pourra facilement accéder.</p>
        <p>Lorsque le client s’est lui-même chargé de faire appel à un transporteur qu’il choisit lui-même, la livraison
          est réputée effectuée dès la remise des produits commandés par le vendeur au transporteur dès lors qu’il a
          remis les produits vendus au transporteur qui les a acceptés sans réserve. Le client reconnaît donc que c’est
          au transporteur qu’il appartient d’effectuer la livraison et ne dispose d’aucun recours en garantie contre le
          vendeur en cas de défaut de livraison des marchandises transportées.</p>
        <p>En cas de demande particulière du client concernant les conditions d’emballage ou de transport des produits
          commandés, dûment acceptées par écrit par le vendeur, les coûts y liés feront l’objet d’une facturation
          spécifique complémentaire, sur devis préalablement accepté par écrit par le client.</p>
        <p>Le client est tenu de vérifier l’état des produits livrés. Il dispose d’un délai de “24 h00” à compter de la
          livraison pour formuler “Déterminez le mode de réclamation toutes réserves ou réclamations pour non-conformité
          ou vice apparent des produits livrés (par exemple colis endommagé déjà ouvert …), avec tous les justificatifs
          y afférents (photos notamment). Passé ce délai et à défaut d’avoir respecté ces formalités, les produits
          seront réputés conformes et exempts de tout vice apparent et aucune réclamation ne pourra être valablement
          acceptée par le vendeur.</p>
        <p>Le vendeur remboursera ou remplacera, dans les plus brefs délais et à ses frais, les produits livrés dont les
          défauts de conformité ou les vices apparents ou cachés auront été dûment prouvés par le client, dans les
          conditions prévues aux articles L 211-4 et suivants du Code de la consommation et celles prévues aux présentes
          Conditions Générales de Vente (voir garanties, particulièrement).</p>
      </div>
    </section>
    <section>
      <h3>ARTICLE 6 – Transfert de propriété – Transfert des risques</h3>
      <div>
        <p>Le transfert de propriété des produits du vendeur, au profit du client, ne sera réalisé qu’après complet
          paiement du prix par ce dernier, et ce quelle que soit la date de livraison desdits produits.</p>
        <p>Peu importe la date du transfert de propriété des produits, le transfert des risques de perte et de
          détérioration s’y rapportant, ne sera réalisé qu’au moment où le client prendra physiquement possession des
          produits. Les produits voyagent donc aux risques et périls du vendeur.</p>
      </div>
    </section>
    <section>
      <h3>ARTICLE 7 – Droit de rétractation</h3>
      <div>
        <p>Conformément aux dispositions de l’article L121-21-8 4°du code de la consommation, les ventes des produits
          par La Famille Boutrais constituant des denrées périssables sont exclues du droit de rétractation édictées par
          l’article L121-21 du code de la consommation.</p>
      </div>
    </section>
    <section>
      <h3>ARTICLE 8 – Responsabilité du vendeur – Garantie</h3>
      <div>
        <p>Les produits vendus sur le site Internet lafamilleboutrais.com sont conformes à la réglementation en vigueur
          en France et ont des performances compatibles avec des usages non professionnels.</p>
        <p>Les produits fournis par le vendeur bénéficient de plein droit et sans paiement complémentaire,
          indépendamment du droit de rétractation, conformément aux dispositions légales,</p>
        <p>– de la garantie légale de conformité, pour les produits apparemment défectueux, abîmés ou endommagés ou ne
          correspondant pas à la commande,</p>
        <p>– de la garantie légale contre les vices cachés provenant d’un défaut de matière, de conception ou de
          fabrication affectant les produits livrés et les rendant impropres à l’utilisation,</p>
        <p>dans les conditions et selon les modalités définies en annexe aux présentes Conditions Générales de Vente
          (Garantie de Conformité / Garantie des Vices Cachés).</p>
        <p>Afin de faire valoir ses droits, le client devra informer le vendeur, par écrit, de la non-conformité des
          produits dans un délai maximum de 24 heures à compter de la livraison des produits ou l’existence des vices
          cachés dans un délai maximum de 48 heures à compter de leur découverte.</p>
        <p>Le vendeur remboursera, remplacera ou fera réparer les produits ou pièces sous garantie jugés non conformes
          ou défectueux.</p>
        <p>Les frais d’envoi seront remboursés sur la base du tarif facturé et les frais de retour seront remboursés sur
          présentation des justificatifs.</p>
        <p>Les remboursements des produits jugés non conformes ou défectueux seront effectués dans les meilleurs délais
          et au plus tard dans les 2 jours suivant la constatation par le vendeur du défaut de conformité ou du vice
          caché.</p>
        <p>Le remboursement s’effectuera par crédit sur le compte bancaire du client ou par chèque bancaire adressé au
          client.</p>
        <p>La responsabilité du vendeur ne saurait être engagée dans les cas suivants :</p>
        <p>– non-respect de la législation du pays dans lequel les produits sont livrés, qu’il appartient au client de
          vérifier,</p>
        <p>– en cas de mauvaise utilisation, d’utilisation à des fins professionnelles, négligence ou défaut d’entretien
          de la part du client, comme en cas d’usure normale du produit, d’accident ou de force majeure.</p>
        <p>La garantie du vendeur est, en tout état de cause, limitée au remplacement ou au remboursement des produits
          non conformes ou affectés d’un vice.</p>
      </div>
    </section>
    <section>
      <h3>ARTICLE 9 – Informatiques et Libertés</h3>
      <div>
        <p>En application de la loi 78-17 du 6 janvier 1978, il est rappelé que les données nominatives qui sont
          demandées au client sont nécessaires au traitement de sa commande et à l’établissement des factures,
          notamment.</p>
        <p>Ces données peuvent être communiquées aux éventuels partenaires du vendeur chargés de l’exécution, du
          traitement, de la gestion et du paiement des commandes.</p>
        <p>Le traitement des informations communiquées par l’intermédiaire du site Internet : lafamilleboutrais.com a
          fait l’objet a fait l’objet d’une déclaration auprès de la CNIL.</p>
        <p>Le client dispose, conformément aux réglementations nationales et européennes en vigueur, d’un droit d’accès
          permanent, de modification, de rectification et d’opposition s’agissant des informations le concernant.</p>
        <p>Ce droit peut être exercé dans les conditions et selon les modalités définies sur le site Internet :
          lafamilleboutrais.com</p>
      </div>
    </section>
    <section>
      <h3>ARTICLE 10 – Propriété intellectuelle</h3>
      <div>
        <p>Le contenu du site Internet lafamilleboutrais.com est la propriété du vendeur et de ses partenaires et est
          protégé par les lois françaises et internationales relatives à la propriété intellectuelle.</p>
        <p>Toute reproduction totale ou partielle de ce contenu est strictement interdite et est susceptible de
          constituer un délit de contrefaçon.</p>
        <p>En outre, le vendeur reste propriétaire de tous les droits de propriété intellectuelle sur les photographies,
          présentations, études, dessins, modèles, prototypes, etc, réalisés (même à la demande du client) en vue de la
          fourniture des services au client. Le client s’interdit donc toute reproduction ou exploitation desdites
          études, dessins, modèles et prototypes, etc, sans l’autorisation expresse, écrite et préalable du vendeur qui
          peut la conditionner à une contrepartie financière.</p>
      </div>
    </section>
    <section>
      <h3>ARTICLE 11 – Droit applicable – Langue</h3>
      <div>
        <p>Les présentes Conditions Générales de Vente et les opérations qui en découlent sont régies et soumises au
          droit français.</p>
        <p>Les présentes Conditions Générales de Vente sont rédigées en langue française. Dans le cas où elles seraient
          traduites en une ou plusieurs langues étrangères, seul le texte français ferait foi en cas de litige.</p>
      </div>
    </section>
    <section>
      <h3>ARTICLE 12 – Litiges</h3>
      <div>
        <p>Tous les litiges auxquels les opérations d’achat et de vente conclues en application des présentes conditions
          générales de vente pourraient donner lieu, concernant tant leur validité, leur interprétation, leur exécution,
          leur résiliation, leurs conséquences et leurs suites et qui n’auraient pu être résolues entre le vendeur et le
          client seront soumis aux tribunaux compétents dans les conditions de droit commun.</p>
        <p>Le Client est informé qu’il peut en tout état de cause recourir à une médiation conventionnelle, notamment
          auprès de la Commission de la médiation de la consommation (C. consom. art. L 534-7) ou auprès des instances
          de médiation sectorielles existantes, et dont les références figurent sur le site Internet
          lafamilleboutrais.com ou à tout mode alternatif de règlement des différends (conciliation, par exemple) en cas
          de contestation.</p>
      </div>
    </section>
    <section>
      <h3>ARTICLE 13 – Information précontractuelle – Acceptation du Clien</h3>
      <div>
        <p>Le client reconnait avoir eu communication, préalablement à la passation de sa commande, d’une manière
          lisible et compréhensible, des présentes Conditions Générales de Vente et de toutes les informations et
          renseignements visés aux articles L111-1 à L111-7, et en particulier :</p>
        <p>– les caractéristiques essentielles du produit, compte tenu du support de communication utilisé et du produit
          concerné ;</p>
        <p>– le prix des produits et des frais annexes (livraison, par exemple) ;</p>
        <p>– en l’absence d’exécution immédiate du contrat, la date ou le délai auquel le vendeur s’engage à livrer le
          produit ;</p>
        <p>– les informations relatives à l’identité du vendeur, à ses coordonnées postales, téléphoniques et
          électroniques, et à ses activités, si elles ne ressortent pas du contexte,</p>
        <p>– les informations relatives aux garanties légales et contractuelles et à leurs modalités de mise en œuvre
          ;</p>
        <p>– les fonctionnalités du contenu numérique ;</p>
        <p>– la possibilité de recourir à une médiation conventionnelle en cas de litige ;</p>
        <p>– les informations relatives au droit de rétractation (existence, conditions, délai, modalités d’exercice de
          ce droit et formulaire type de rétractation), aux frais de renvoi des produits, aux modalités de résiliation
          et autres conditions contractuelles importantes.</p>
        <p>Le fait pour une personne physique (ou morale), de commander sur le site Internet lafamilleboutrais.com
          emporte adhésion et acceptation pleine et entière des présentes Conditions Générales de Vente, ce qui est
          expressément reconnu par le client, qui renonce, notamment, à se prévaloir de tout document contradictoire,
          qui serait inopposable au vendeur.</p>
      </div>
    </section>
    <section>
      <h3>ARTICLES 14 – Contacts</h3>
      <div>
        <p>La Famille Boutrais est une SARL au Capital Social de 10.000€, dont le siège social est situé : Les
          Mousquemers, Zone d'activités de Bel Event – 35350 St Coulomb, immatriculée au Registre du Commerce et des
          Sociétés de St Malo, sous le numéro SIRET 528 404 247, représentée par M. Hugo BOUTRAIS en qualité de gérant,
          dûment habilité aux fins des présentes.</p>
        <p>La société peut être jointe par email en cliquant sur le formulaire de contact accessible via la page
          d’accueil du site.</p>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: "GeneralConditionsSale",
  metaInfo: {
    title: 'CGV',
  },
}
</script>

<style lang="scss" type="text/scss" scoped>
.title {
  text-align: center;
  color: $Brown;
}

.infos {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 1%;
  font-size: small;
}

section {
  h3 {
    margin-left: 10%;
    color: $Brown;
    font-size: medium;
    margin-bottom: 1%;
  }

  p {
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 1%;
    font-size: small;
    font-style: italic;
  }
}
</style>