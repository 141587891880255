import {getAPI} from "@/services/axios-api";
import {TokenService} from "@/services/token.service";

const setup = (store) => {
    getAPI.interceptors.request.use(
        (config) => {
            let token = TokenService.getLocalAccessToken();
            if (token) {
                token = token.replace(/^"(.*)"$/, '$1');
                config.headers["Authorization"] = token;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    getAPI.interceptors.response.use(
        (response) => {
            return response;
        },
        async (err) => {
            const originalConfig = err.config;
            if (originalConfig.url !== "/account/login/" && err.response) {
                if (err.response.status === 401 && originalConfig.url !== "/account/token/refresh/" && !originalConfig._retry) {
                    originalConfig._retry = true;
                    try {
                        const rs = await getAPI.post("/account/token/refresh/", {
                            refresh: TokenService.getLocalRefreshToken(),
                        });
                        const accessToken = rs.data.access;
                        store.dispatch('auth/refreshToken', accessToken);
                        TokenService.updateLocalAccessToken(accessToken);
                        return getAPI(originalConfig);
                    } catch (_error) {
                        store.dispatch('auth/removeToken');
                        return Promise.reject(_error);
                    }
                }
            }
            return Promise.reject(err);
        }
    );
};

export default setup;