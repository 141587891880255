export class Utils {
    static compareArrays(a, b) {
        if (a.length !== b.length) return false;
        const uniqueValues = new Set([...a, ...b]);
        for(const v of  uniqueValues) {
            const aCount = a.filter(e => e === v).length;
            const bCount = b.filter(e => e === v).length;
            if (aCount !== bCount) return false;
        }
        return true;
    }

    static getImageSrc(srcset) {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 320) {
            return require('@/assets-2/' + this.getImageFromSrcset(srcset, "320w"));
        } else if (screenWidth <= 640) {
            return require('@/assets-2/' + this.getImageFromSrcset(srcset, "640w"));
        } else {
            return require('@/assets-2/' + this.getImageFromSrcset(srcset, "1280w"));
        }
    }

    static getImageFromSrcset(srcset, size) {
        const images = srcset.split(',').map(item => item.trim());
        for (let img of images) {
            if (img.includes(size)) {
                return img.split(' ')[0];
            }
        }
        return "";
    }

    static getLowerPrice(prices, product) {
        let lowerPrice = 0.0
        if (prices.length !== 0 && product) {
            product.products.forEach(e => {
                let item = prices.find(i => {
                    return i.product.id === e;
                })
                if (lowerPrice === 0 || lowerPrice > item.price_it) {
                    lowerPrice = item.price_it;
                }
            })
            return lowerPrice;
        } else return 'N/A'
    }

    static isSameCombo (a, b) {
        return parseInt(a.id) === parseInt(b.id) && parseInt(a.attribute_id) === parseInt(b.attribute_id)
    }

    static onlyLeft (left, right, compare) {
        return left.filter(lValue => {
            return !right.some(rValue => {
                return compare(lValue, rValue)
            })
        })
    }

    static isPresent(a, b) {
        const onlyInA = this.onlyLeft(a, b, this.isSameCombo)
        const onlyInB = this.onlyLeft(b, a, this.isSameCombo)
        if([...onlyInA, ...onlyInB].length === 0) return true;
        else if (onlyInA.length === 0) return true;
        else return false;
    }

    static loadScript(src, attributes, callback) {
        return new Promise((resolve, reject) => {
            // Check if script already exists
            if (document.querySelector(`script[src="${src}"]`)) {
                resolve();
                return;
            }
            const script = document.createElement('script');
            if (attributes) {
                Object.keys(attributes).forEach(key => {
                    script.setAttribute(key, attributes[key]);
                });
            }
            script.src = src;
            script.async = true;
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
        });
    }

    static loadStylesheet(href) {
        return new Promise((resolve, reject) => {
            // Check if link already exists
            if (document.querySelector(`link[href="${href}"]`)) {
                resolve();
                return;
            }
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = href;
            link.onload = resolve;
            link.onerror = reject;
            document.head.appendChild(link);
        });
    }

}

export default new Utils()