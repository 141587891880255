import axios from "axios";

const getAPI = axios.create({
     // baseURL: 'https://django.lafamilleboutrais.tech',
    baseURL: 'https://django.lafamilleboutrais.com',
    //baseURL: 'http://127.0.0.1:8000/',
    timeout: 60000,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X_CSRFTOKEN'
})


export {getAPI}
