import {getAPI} from "@/services/axios-api";
import {CartService} from "@/services/cart.service";

const state = () => ({
    cart: {
        items: [],
        shippingCostIT: 0,
        shippingCostDF: 0,
        clickncollect: false,
        multiple: false,
        route_id: null,
    },
    blockLocate: true,
    discount: null,
    amount_discount: 0.00,
});

const getters = {
    isSplit: function (state) {
        return state.cart.multiple
    },
    getAmountDiscount: function (state) {
        return parseFloat(state.amount_discount)
    },
    getDiscount: function (state) {
        return state.discount
    },
    getBlockLocate: function (state) {
        return state.blockLocate
    },
    getMyCartItems: function (state) {
        return state.cart.items
    },
    getShippingCost: function (state) {
        if (state.cart.clickncollect) {
            return 0
        }
        return parseFloat(state.cart.shippingCostIT)
    },
    getMyCart: function (state) {
        return state.cart
    },
    getTotalPrice: function (state) {
        let amount = 0
        if (state.cart.items.length > 0) {
            for (let elm of state.cart.items) {
                amount += (elm.price * elm.quantity)
            }
        }
        return amount.toFixed(2)
    },
    getTotal: function (state, getters) {
        let shipping = false
        let total = 0;
        let isNumber = function (n) {
            return !isNaN(parseFloat(n)) && isFinite(n)
        }
        if (isNumber(getters.getTotalPrice) && isNumber(getters.getShippingCost)) {
            total = parseFloat(getters.getTotalPrice)
        } else total = 0
        if (state.discount) {
            let products = null;
            switch (state.discount.amount_state) {
                case 'percent':
                    if (state.discount.product.length !== 0 && state.discount.product_tmpl.length !== 0) {
                        products = getters.getMyCartItems.filter(e => state.discount.product.includes(e.product.id))
                        products += getters.getMyCartItems.filter(e => state.discount.product_tmpl.includes(e.product.product_tmpl_id))
                        products = Array.from(new Set(products.map(a => a.id)))
                            .map(id => {
                                return products.find(a => a.id === id)
                            })
                    } else if (state.discount.product.length !== 0) {
                        products = getters.getMyCartItems.filter(e => state.discount.product.includes(e.product.id))
                    } else if (state.discount.product_tmpl.length !== 0) {
                        products = getters.getMyCartItems.filter(e => state.discount.product_tmpl.includes(e.product.product_tmpl_id))
                    } else {
                        state.amount_discount = parseFloat(total * state.discount.amount / 100).toFixed(2)
                        total = total - state.amount_discount + getters.getShippingCost
                        shipping = true;
                    }
                    let sub_amount = 0
                    if (products) {
                        for (let elm of products) {
                            sub_amount += (elm.price * elm.quantity)
                        }
                        state.amount_discount = parseFloat(sub_amount * state.discount.amount / 100).toFixed(2)
                        total = total - sub_amount + (sub_amount - state.amount_discount)
                        total += getters.getShippingCost
                        shipping = true;
                    }
                    break;
                case 'fix':
                    state.amount_discount = parseFloat(state.discount.amount).toFixed(2)
                    total = Math.max(0, (total + getters.getShippingCost - state.discount.amount));
                    shipping = true;
                    break;
            }
        }
        if (!shipping) {
            total += parseFloat(getters.getShippingCost)
        }
        return parseFloat(total).toFixed(2)
    },
    getCartLength: function (state) {
        let total = 0
        state.cart.items.forEach(e => {
            total += parseInt(e.quantity)
        })
        return parseInt(total)
    },
};

const actions = {
    initializeCart: async function ({rootState, commit, dispatch}, data) {
        if (data) {
            if (rootState.pricelist.pricelistItems.length === 0) await dispatch('pricelist/loadPricelistTransport', null, {root: true})
            let cart_service = new CartService(rootState.pricelist.pricelistItems);
            let cartVerify = cart_service.verifyCart(data);
            if (data.length !== cartVerify.length) {
                const itemsError = cart_service.getItems();
                let msg = "Ces produits ne sont pas disponible à la livraison dans votre zone: \n"
                dispatch('error/setError', {message: msg, detail: itemsError}, {root: true})
            }

            commit('setCartItems', cartVerify)
            dispatch('getShippingCost')
        }
    },
    changeLocateStatus: function (context, bool) {
        context.commit('setBlockLocate', bool)
    },
    addToCart: async function ({commit, state, rootState, dispatch}, itemToAdd) {
        const item = {
            product: itemToAdd.product,
            quantity: itemToAdd.quantity,
            price_df: itemToAdd.price_df,
            price: itemToAdd.price,
            weight: itemToAdd.product.weight
        }
        const eventParams = {
            currency: "EUR",
            value: item.price,
            items: [
                {
                    item_id: item.product.default_code,
                    item_name: item.product.name,
                    price: item.price,
                    quantity: item.quantity
                }
            ]
        }
        dispatch('google/trackEvent', {action: 'add_to_cart', eventParams: eventParams}, {root: true})
        commit('addToCart', item)
        dispatch('updateCartCache')
        dispatch('getShippingCost')
    },
    removeUnitFromCart: async function ({commit, state, rootState, dispatch}, itemToRemove) {
        const item = {
            product: itemToRemove.product,
            quantity: 1,
            price_df: itemToRemove.price_df,
            price: itemToRemove.price,
            weight: itemToRemove.product.weight
        }
        const eventParams = {
            currency: "EUR",
            value: item.price,
            items: [
                {
                    item_id: item.product.default_code,
                    item_name: item.product.name,
                    price: item.price,
                    quantity: item.quantity
                }
            ]
        }
        dispatch('google/trackEvent', {action: 'remove_from_cart', eventParams: eventParams}, {root: true})
        commit('removeUnitFromCart', item)
        dispatch('updateCartCache')
        dispatch('getShippingCost')
    },
    removeFromCart: function ({commit, state, dispatch}, index) {
        const eventParams = {
            currency: "EUR",
            value: state.cart.items[index].price,
            items: [
                {
                    item_id: state.cart.items[index].product.default_code,
                    item_name: state.cart.items[index].product.name,
                    price: state.cart.items[index].price,
                    quantity: state.cart.items[index].quantity
                }
            ]
        }
        dispatch('google/trackEvent', {action: 'remove_from_cart', eventParams: eventParams}, {root: true})
        commit('removeFromCart', index)
        dispatch('updateCartCache')
        dispatch('getShippingCost')
    },
    setRoute: function ({commit}, item) {
        commit('setRoute', item)
    },
    getShippingCost: async function ({commit, state}) {
        await getAPI
            .post('/shipping/shipping-cost/', state.cart)
            .then(response => {
                commit('setShippingCost', response.data);
            })
    },
    changeClickNCollect: function ({commit}, data) {
        commit('changeClickNCollect', data)
    },
    clearCart: function ({commit, dispatch}) {
        commit('clearCart');
        commit('clearDiscount');
        dispatch("updateCartCache")
    },
    addFixQuantity: async function ({commit, state, rootState, dispatch}, data) {
        commit('addFixQuantity', data)
        dispatch('getShippingCost')
    },
    updateCartCache: async function (context) {
        const data = {
            "cart": JSON.stringify(context.state.cart.items),
            "route_id": context.state.cart.route_id,
        }
        await context.dispatch('auth/setServerCache', data, {root: true})
    },
    setDiscount: function (context, data) {
        context.commit('setDiscount', data)
    }
};

const mutations = {
    changeClickNCollect: function (state, data) {
        state.cart.clickncollect = data;
    },
    setBlockLocate: function (state, bool) {
        state.blockLocate = bool
    },
    addToCart: function (state, item) {
        const exists = state.cart.items.filter(i =>
            i.product.id === item.product.id
        );
        if (exists.length) {
            exists[0].quantity = (parseInt(exists[0].quantity) + parseInt(item.quantity)).toFixed(0);
            exists[0].weight = (parseFloat(exists[0].weight) + parseFloat(item.weight)).toFixed(2);
        } else {
            state.cart.items.push(item)
        }
    },
    removeUnitFromCart: function (state, item) {
        const exists = state.cart.items.find(i => i.product.id === item.product.id);
        const index = state.cart.items.findIndex(i => i.product.id === item.product.id);
        const resultQuantity = (parseInt(exists.quantity) - parseInt(item.quantity)).toFixed(0);
        const resultWeight = (parseFloat(exists.weight) - parseFloat(item.weight)).toFixed(2);

        if (resultQuantity <= 0) {
            state.cart.items.splice(index, 1);
        } else {
            exists.quantity = resultQuantity;
            exists.weight = resultWeight;
            state.cart.items[index] = exists;
        }
    },
    removeFromCart: function (state, index) {
        state.cart.items.splice(index, 1);
    },
    setShippingCost: function (state, item) {
        state.cart.shippingCostIT = item.shipping_cost.it
        state.cart.shippingCostDF = item.shipping_cost.df
        state.cart.multiple = item.shipping_cost.multiple
    },
    setRoute: function (state, item) {
        state.cart.route_id = item.id
    },
    clearCart: function (state) {
        state.cart.items = []
        state.cart.shippingCostIT = 0
        state.cart.shippingCostDF = 0
        state.discount = null
    },
    setCartItems: function (state, data) {
        state.cart.items = data
    },
    setDiscount: function (state, data) {
        state.discount = data
    },
    addFixQuantity: function (state, data) {
        state.cart.items.splice(data.index, 1, data.item)
    },
    clearDiscount: function (state) {
        state.discount = null
        state.amount_discount = 0.00
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};